import { gql, useQuery } from "@apollo/client";
import { Trade } from "../types";

const GET_TRADES = gql`
	query GetTrades($proId: String) {
		trades {
			id
			name
			description
			displayPriority
			imageUrl
			levels {
				id
				order
				requirements {
					id
					name
					description
					price
					prompt
					type
				}
				certificate(proId: $proId) {
					issuedAt
				}
			}
			currentLevel(proId: $proId)
			category {
				id
				name
				proTitle
			}
		}
	}
`;

export const useTrades = (proId?: string) => {
  const { loading, error, data } = useQuery<{
    trades: Trade[];
  }>(GET_TRADES, {
    variables: {
      proId,
    },
  });

  return {
    loading,
    error,
    trades: data?.trades,
  };
};
