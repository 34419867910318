import {
	Stack,
	Card,
	Image as ChakraImage,
	CardBody,
	Text,
	CardFooter,
	Button,
	VStack,
	HStack,
	Icon,
	List,
	ListItem,
	ListIcon,
	Badge,
	Link,
	Modal,
	ModalHeader,
	ModalContent,
	ModalOverlay,
} from "@chakra-ui/react";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Trade, TradeLevel, TradeRequirementType } from "../../types";
import CertificateThumbnail from "../../assets/laborhack-certificate.png";
import { LEVEL_ORDER_MARKETING_NAMES } from "../../constants";
import { MdCheckCircle, MdRemoveCircle, MdTrendingUp } from "react-icons/md";
import { DownloadIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { logEvent } from "../../utlis/analytics";
import { useToggles } from "../../hooks/useToggles";
import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { format } from "date-fns";
import CertificatePDF from "../../assets/laborhack-certificate.pdf";
import NameFont from "../../assets/PinyonScript-Regular.ttf";
import { GetMyProfileQuery } from "../../__generated__/graphql";

interface CertificateListItemProps {
	pro: GetMyProfileQuery["myProInformation"];
	trade: Trade;
	tradeLevel: TradeLevel;
	hasSubmittedBankAccount?: boolean;
	hasSubmittedGuarantor?: boolean;
}

export const CertificateListItem = ({
	pro,
	trade,
	tradeLevel,
}: PropsWithChildren<CertificateListItemProps>) => {
	const { toggles } = useToggles();

	const certificate = tradeLevel.certificate;

	const hasCompletedLevelRequirements =
		trade.currentLevel &&
		trade.currentLevel >= tradeLevel.order &&
		certificate !== null;

	const isActive = hasCompletedLevelRequirements;

	const [showShareModal, setShowShareModal] = useState(false);

	const [certificatePdf, setCertificatePdf] = useState<File>();

	const [loading, setLoading] = useState(false);

	const handleDownload = () => {
		logEvent("download_certificate_clicked");
		setLoading(true);

		download(certificatePdf!, "certificate.pdf", "application/pdf");

		setLoading(false);
	};

	const generateCertificatePdf = useCallback(async () => {
		try {
			const fontBytes = await fetch(NameFont).then((response) =>
				response.arrayBuffer()
			);

			// Load the imported PDF file
			const pdfBytes = await fetch(CertificatePDF).then((response) =>
				response.arrayBuffer()
			);

			const pdfDoc = await PDFDocument.load(pdfBytes);

			pdfDoc.registerFontkit(fontkit);

			const nameFont = await pdfDoc.embedFont(fontBytes, {
				subset: true,
			});

			const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

			const page = pdfDoc.getPages()[0];

			const fontSize = 14;

			const name = `${pro.firstName} ${pro.lastName}`;

			const nameFontSize = 42;

			const nameWidth = nameFont.widthOfTextAtSize(name, nameFontSize);

			const level = LEVEL_ORDER_MARKETING_NAMES[tradeLevel.order].name;

			const levelWidth = helveticaFont.widthOfTextAtSize(level, fontSize);

			const tradeName = trade.name;

			const tradeNameWidth = helveticaFont.widthOfTextAtSize(
				tradeName,
				fontSize
			);

			const signedDate = format(new Date(certificate?.issuedAt!), "PPPP");

			const signedDateWidth = helveticaFont.widthOfTextAtSize(
				signedDate,
				fontSize
			);

			page.drawText(name, {
				x: 416 - nameWidth / 2,
				y: 288,
				size: nameFontSize,
				font: nameFont,
				color: rgb(0, 0, 0),
			});

			page.drawText(tradeName, {
				x: 350 - tradeNameWidth / 2,
				y: 245,
				size: fontSize,
				font: helveticaFont,
				color: rgb(0, 0, 0),
			});

			page.drawText(level, {
				x: 580 - levelWidth / 2,
				y: 245,
				size: fontSize,
				font: helveticaFont,
				color: rgb(0, 0, 0),
			});

			page.drawText(signedDate, {
				x: 193 - signedDateWidth / 2,
				y: 118,
				size: fontSize,

				font: helveticaFont,
				color: rgb(0, 0, 0),
			});

			const modifiedPdfBytes = await pdfDoc.save();

			console.log("Certificate generated successfully");

			return modifiedPdfBytes;
		} catch (error) {
			console.error("Error generating certificate:", error);
			throw error;
		}
	}, [pro, tradeLevel, trade.name, certificate?.issuedAt]);

	const initialize = useCallback(async () => {
		setLoading(true);

		const pdfBytes = await generateCertificatePdf();

		const file = new File([pdfBytes], "certificate.pdf", {
			type: "application/pdf",
		});

		setCertificatePdf(file);

		setLoading(false);
	}, [generateCertificatePdf]);

	useEffect(() => {
		if (isActive) {
			initialize();
		}
	}, [initialize, isActive, certificate]);

	return (
		<>
			<Modal
				isOpen={showShareModal}
				onClose={() => setShowShareModal(false)}
				isCentered
				closeOnOverlayClick
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Share Your Certificate</ModalHeader>
					<VStack pb={8} px={6} alignItems="start">
						<HStack>
							<Link
								href={`https://twitter.com/intent/tweet?text=I%20just%20earned%20the%20LaborHack%20${trade.name}%20certificate!%20https://www.laborhack.com/artisans`}
								isExternal
							>
								<Button
									rightIcon={<FaTwitter />}
									colorScheme="twitter"
									variant="solid"
									onClick={() => logEvent("share_certificate_twitter_clicked")}
								>
									Tweet
								</Button>
							</Link>
							<Link
								href={`https://www.facebook.com/sharer.php?u=www.laborhack.com/artisans`}
								isExternal
							>
								<Button
									rightIcon={<FaFacebookF />}
									colorScheme="facebook"
									variant="solid"
									onClick={() => logEvent("share_certificate_facebook_clicked")}
								>
									Post to Facebook
								</Button>
							</Link>
						</HStack>
					</VStack>
				</ModalContent>
			</Modal>
			<Card
				direction={{
					base: "column",
					sm: "row",
				}}
				overflow="hidden"
				variant="outline"
			>
				<ChakraImage
					filter={isActive ? "none" : "grayscale(100%)"}
					objectFit="cover"
					maxW={{ base: "100%", sm: "200px" }}
					src={CertificateThumbnail}
					alt="Certificate thumbnail"
				/>

				<Stack>
					<CardBody>
						<VStack align="start" spacing={4}>
							<VStack align="start">
								<HStack
									w={{
										base: "full",
										md: "500px",
									}}
								>
									<Text fontSize="md" fontWeight="semibold" color="primary.500">
										LaborHack {trade.name} -{" "}
										{LEVEL_ORDER_MARKETING_NAMES[tradeLevel.order].name}
									</Text>
								</HStack>

								<HStack align="center" gap={2}>
									<HStack
										border="1px"
										rounded="full"
										paddingY="1"
										paddingX="3"
										borderColor="primary.200"
									>
										<Icon as={MdTrendingUp} />
										<Text fontSize="sm" color="primary.500">
											Level {tradeLevel.order} of {trade.levels.length}
										</Text>
									</HStack>
									{isActive && (
										<Badge fontSize="md" colorScheme="green">
											Complete
										</Badge>
									)}
								</HStack>
							</VStack>

							<VStack align="start" spacing={1}>
								<Text color="primary.500">
									Requirement to earn this certificate
								</Text>
								<List>
									{hasCompletedLevelRequirements ? (
										tradeLevel.requirements
											.slice()
											.filter((requirement) => {
												if (!toggles.show_interview_requirements) {
													return (
														requirement.type !== TradeRequirementType.INTERVIEW
													);
												}

												return true;
											})
											.map((requirement) => (
												<ListItem marginY={1} key={requirement.id}>
													<HStack spacing={1}>
														<ListIcon as={MdCheckCircle} color="green.500" />
														<Text fontSize="sm" color="primary.400">
															{requirement.name}
														</Text>
													</HStack>
												</ListItem>
											))
									) : (
										<ListItem marginY={1}>
											<HStack spacing={1}>
												<ListIcon as={MdRemoveCircle} color="gray.500" />
												<Text fontSize="sm" color="primary.400">
													Complete level {tradeLevel.order}{" "}
													{tradeLevel.order > 1 && `and previous level`}{" "}
													requirements.{" "}
													<Link
														as={RouterLink}
														to={`/trades/${trade.id}`}
														color="brand.500"
													>
														Take Me There
													</Link>
												</Text>
											</HStack>
										</ListItem>
									)}
								</List>
							</VStack>
						</VStack>
					</CardBody>

					<CardFooter>
						<Stack
							direction={{
								base: "column",
								sm: "row",
							}}
							w="full"
						>
							<Button
								variant="solid"
								colorScheme="primary"
								rightIcon={<DownloadIcon />}
								isDisabled={!isActive}
								isLoading={loading}
								onClick={handleDownload}
								width={{
									base: "full",
									sm: "auto",
								}}
							>
								Download
							</Button>
							{/* <Button
								variant="outline"
								colorScheme="primary"
								rightIcon={<MdShare />}
								isDisabled={true}
								width={{
									base: "full",
									sm: "auto",
								}}
							>
								Share Certificate
							</Button> */}
						</Stack>
					</CardFooter>
				</Stack>
			</Card>
		</>
	);
};
