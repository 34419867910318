import { useAuth0 } from "@auth0/auth0-react";
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
	Icon,
	Button,
	IconButton,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import logo from "../assets/LaborHack.svg";
import { NAV_MENU } from "../constants";
import { useProfile } from "../hooks/useProfile";
import { GiHamburgerMenu } from "react-icons/gi";
import { useToggles } from "../hooks/useToggles";
import { Loading } from "./Loading";
import Avatar from "../assets/avatar.png";
import { IoLogOut } from "react-icons/io5";
import { SideNavItemV2 } from "./NavItem/SideNavItem";

export interface NavProps {}

export const NavV2 = () => {
	const { logout, user } = useAuth0();
	const { profile, loading: profileLoading } = useProfile();
	const location = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { isLoading: isLoadingToggles, toggles } = useToggles();

	if (isLoadingToggles) {
		return <Loading />;
	}

	if (!user) {
		return null;
	}

	return (
		<>
			<div className="w-full py-4 px-8 lg:px-12 bg-[#F9F9F9]">
				<div className="flex w-full justify-between lg:justify-end">
					{/* on smaller screens, the nav would have the logo and a menu button on the left */}
					{/* on large screens only the account section would exist so it would be on the right */}
					<div className="flex items-center lg:hidden">
						{/* logo and menu button */}
						<Icon
							as={GiHamburgerMenu}
							w={7}
							h={6}
							color="primary.500"
							display={{
								base: "block",
								lg: "none",
							}}
							onClick={onOpen}
						/>
						<img
							className="ml-2 block lg:hidden"
							src={logo}
							alt="laborhack's logo"
							width={120}
						></img>
					</div>
					<div className="flex items-center gap-4">
						{/* account section */}
						<div>
							<img src={Avatar} alt="avatar" className="w-8 h-8 rounded-full" />
						</div>
						<IconButton
							display={{
								base: "block",
								lg: "none",
							}}
							w={"fit-content"}
							variant="unstyled"
							aria-label="Log out"
							icon={
								<IoLogOut size={30} style={{ transform: "rotate(180deg)" }} />
							}
							onClick={() =>
								logout({
									returnTo: window.location.origin,
								})
							}
						/>
					</div>
				</div>
			</div>
			<Drawer placement="top" onClose={onClose} isOpen={isOpen} size={"full"}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						<img src={logo} alt="laborhack's logo" width={120} />
					</DrawerHeader>
					<DrawerBody>
						<div className="flex flex-col h-[calc(100vh-120px)]">
							<ul className="flex flex-col gap-3 w-full">
								{NAV_MENU.filter(
									({ shouldRender }) =>
										!profileLoading && shouldRender(profile, toggles)
								).map((item) => {
									return (
										<SideNavItemV2
											key={item.name}
											name={item.name}
											path={item.url}
											locked={!profileLoading && !profile}
											active={location.pathname === item.url}
											icon={item.icon}
											onClose={onClose}
										/>
									);
								})}
							</ul>
							<div className="my-4 px-4">
								<Button
									width={"full"}
									rightIcon={
										<IoLogOut
											size={20}
											style={{ transform: "rotate(180deg)" }}
										/>
									}
									fontSize={"sm"}
									onClick={() =>
										logout({
											returnTo: window.location.origin,
										})
									}
								>
									Logout
								</Button>
							</div>
						</div>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};
