import { useAuth0 } from "@auth0/auth0-react";
import { Button, Checkbox, Link, VStack } from "@chakra-ui/react";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import background from "../../assets/background.jpg";
import { Loading } from "../../components/Loading";
import { Logo } from "../../components/Logo";

export interface LoginProps {}

export const Login: FC<LoginProps> = () => {
	const [checkingForSession, setCheckingForSession] = React.useState(true);
	const {
		isLoading,
		loginWithRedirect,
		isAuthenticated,
		getAccessTokenSilently,
	} = useAuth0();
	const navigate = useNavigate();

	const srcEmail = new URLSearchParams(window.location.search).get("src_email");

	const [isAgreed, setIsAgreed] = React.useState(false);

	const [showNotAgreedError, setShowNotAgreedError] = React.useState(false);
	useEffect(() => {
		getAccessTokenSilently()
			.catch((e) => {
				if (e.error === "login_required") {
					return;
				}

				console.error(e);
			})
			.finally(() => {
				setCheckingForSession(false);
			});
	}, [getAccessTokenSilently]);

	useEffect(() => {
		/**
		 * If the user is already authenticated, redirect to the page they wanted to visit or the dashboard
		 */
		if (isAuthenticated && !isLoading && !checkingForSession) {
			const redirect = localStorage.getItem("redirect");

			if (redirect && redirect !== "/") {
				return navigate(redirect, {
					replace: true,
				});
			}

			navigate("/my-portfolio");
		}
	}, [isAuthenticated, isLoading, navigate, checkingForSession]);

	if (isLoading || checkingForSession) {
		return <Loading />;
	}

	const handleAgree = () => {
		setIsAgreed((prev) => !prev);

		if (showNotAgreedError) {
			setShowNotAgreedError(false);
		}
	};

	const handleSignUp = () => {
		if (!isAgreed) {
			setShowNotAgreedError(true);

			setTimeout(() => {
				setShowNotAgreedError(false);
			}, 5000);
			return;
		}

		loginWithRedirect({
			screen_hint: "signup",
			...(srcEmail !== null ? { login_hint: srcEmail } : {}),
		});
	};

	return (
		<>
			<Helmet>
				<title>Login | LaborHack Pro</title>
			</Helmet>
			<div className="grid grid-cols-1 md:grid-cols-2 w-full h-screen items-center justify-items-center">
				{/* Single column on mobile, two columns on desktop. */}
				<div className="flex flex-col w-full h-full">
					{/* Login page content goes here. */}
					<div className="px-8 lg:px-28 w-full absolute flex flex-col justify-center h-28">
						<Link href={`${process.env.REACT_APP_HOME_APP_URL}`}>
							<Logo />
						</Link>
					</div>
					<div className="flex flex-col justify-center items-center flex-auto px-4 md:px-6">
						<div>
							<h1 className="text-2xl text-primary-500 font-bold mb-2">
								Sign Up
							</h1>

							<p className="text-sm mt-2 text-primary-300">
								Already have an account?{" "}
								<button
									className="text-accent-500 font-semibold"
									onClick={() =>
										loginWithRedirect({
											...(srcEmail !== null ? { login_hint: srcEmail } : {}),
										})
									}
									disabled={isLoading}
								>
									Sign in
								</button>
							</p>
							<div className="mt-8">
								<VStack spacing={4} alignItems="start">
									<Checkbox
										colorScheme={"brand"}
										isChecked={isAgreed}
										onChange={handleAgree}
									>
										<p className="text-sm text-primary-500">
											I agree to LaborHack&apos;s{" "}
											<a
												href={`${process.env.REACT_APP_HOME_APP_URL}/terms`}
												target="_blank"
												rel="noreferrer"
												className=" !text-accent-500"
											>
												Terms
											</a>{" "}
											and{" "}
											<a
												href={`${process.env.REACT_APP_HOME_APP_URL}/privacy`}
												target="_blank"
												rel="noreferrer"
												className=" !text-accent-500"
											>
												Privacy policy
											</a>
											.
										</p>
									</Checkbox>
									<Button
										isLoading={isLoading}
										color={"white"}
										bgColor={"brand.500"}
										size="lg"
										onClick={handleSignUp}
										spinnerPlacement="start"
									>
										Sign up to LaborHack
									</Button>
									{showNotAgreedError && (
										<p className="text-sm text-red-500">
											You must agree to the terms and conditions to continue.
										</p>
									)}
								</VStack>
							</div>
						</div>
					</div>
				</div>
				<div
					className={clsx(
						"hidden md:flex w-full min-h-screen flex-col items-center justify-center"
					)}
					style={{
						backgroundImage: `url(${background})`,
						backgroundSize: "cover",
					}}
				>
					{/* promotional content goes here. */}
				</div>
			</div>
		</>
	);
};
