import {
	Alert,
	AlertIcon,
	Flex,
	HStack,
	Skeleton,
	Text,
	VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import { CertificateListItem } from "../../features/certificates/CertificateListItem";
import { useBackGroundCheck } from "../../hooks/useBackgroundCheck";
import { useBankAccountInfo } from "../../hooks/useBankAccountInfo";
import { useCategories } from "../../hooks/useCategories";
import { useProfile } from "../../hooks/useProfile";
import { useTrades } from "../../hooks/useTrades";
import { Trade } from "../../types";

const CertificatePage = () => {
	const {
		loading: profileLoading,
		error: profileError,
		profile,
	} = useProfile();

	const {
		loading: tradesLoading,
		error: tradesError,
		trades,
	} = useTrades(profile?.id);

	const {
		loading: categoriesLoading,
		error: categoriesError,
		categories,
	} = useCategories();

	const { backgroundCheckData } = useBackGroundCheck();
	const { hasProvidedBankAccountInfo } = useBankAccountInfo();

	const hasBackgroundCheckData = !!backgroundCheckData?.isComplete;

	const loading = profileLoading || tradesLoading || categoriesLoading;

	const error = profileError || tradesError || categoriesError;

	const { enrolledTrades, otherTrades, yourTrades, showYourTrades } =
		useMemo(() => {
			const enrolledTradeIdsSet = new Set(
				profile?.trades.map((trade) => trade.id) || []
			);

			const categoryIdsSet = new Set(profile?.categoryIds || []);

			const enrolledTrades: Trade[] = [];
			const otherTrades: Trade[] = [];
			const yourTrades: Trade[] = [];

			let yourTradeIsEnrolled = false;

			const tradesSortedByDisplayPriority = trades?.slice().sort((a, b) => {
				/**
				 * The lower display priority value is shown first.
				 */

				const aPriority = a.displayPriority || Number.MAX_SAFE_INTEGER;
				const bPriority = b.displayPriority || Number.MAX_SAFE_INTEGER;

				if (aPriority < bPriority) return -1;

				if (aPriority > bPriority) return 1;

				return 0;
			});

			tradesSortedByDisplayPriority?.forEach((trade) => {
				if (enrolledTradeIdsSet.has(trade.id)) {
					enrolledTrades.push(trade);

					if (categoryIdsSet.has(trade.category.id)) {
						yourTradeIsEnrolled = true;
					}
				} else if (categoryIdsSet.has(trade.category.id)) {
					yourTrades.push(trade);
				} else {
					otherTrades.push(trade);
				}
			});

			const allYourTradesAreEnrolled =
				yourTradeIsEnrolled && yourTrades.length === 0;

			/**
			 * Sort enrolled trades by order of trades in profile.
			 */

			enrolledTrades.sort((a, b) => {
				const aIndex = profile!.trades.findIndex(
					(profileTrade) => profileTrade.id === a.id
				);

				const bIndex = profile!.trades.findIndex(
					(profileTrade) => profileTrade.id === b.id
				);

				if (aIndex > bIndex) return -1;

				if (aIndex < bIndex) return 1;

				return 0;
			});

			return {
				enrolledTrades,
				otherTrades,
				yourTrades,
				showYourTrades: !allYourTradesAreEnrolled,
			};
		}, [trades, profile]);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <RequestFailed text={error.message} />;
	}

	if (!profile || !trades || !categories) {
		return <RequestFailed />;
	}

	return (
		<>
			<Helmet>
				<title>Certificates | LaborHack Pro Dashboard</title>
			</Helmet>

			<VStack align="start" gap={16} w="full">
				<VStack align="start">
					<Text fontSize="2xl" fontWeight="semibold" color="primary.500">
						My Certificates
					</Text>
					<Text className="!m-0" color="primary.300">
						Your certificates will be displayed here once you have completed
						their trade levels
					</Text>
				</VStack>
				{!enrolledTrades.length && !yourTrades.length && (
					<Alert status="info" variant={"solid"}>
						<AlertIcon />
						Sorry, we do not have any certifications for your profession at the
						moment
					</Alert>
				)}
				{enrolledTrades.length && (
					<VStack align="start" w="full">
						<HStack align="start" w="full">
							<Text
								fontSize="xl"
								fontWeight="semibold"
								className="!m-0"
								color="primary.500"
							>
								Enrolled certificates
							</Text>
						</HStack>
						{loading && <Skeleton height="20px" width="full" />}
						{enrolledTrades?.map((trade) => (
							<VStack key={trade.id}>
								<Flex wrap="wrap" gap="4" w="full">
									{trade.levels.map((level) => (
										<CertificateListItem
											key={level.id}
											pro={profile}
											trade={trade}
											tradeLevel={level}
											hasSubmittedBankAccount={hasProvidedBankAccountInfo}
											hasSubmittedGuarantor={hasBackgroundCheckData}
										/>
									))}
								</Flex>
							</VStack>
						))}
					</VStack>
				)}
				{yourTrades.length && showYourTrades && (
					<VStack align="start" w="full">
						<HStack align="start" w="full">
							<Text
								fontSize="xl"
								fontWeight="semibold"
								className="!m-0"
								color="primary.500"
							>
								Certificates for your profession
							</Text>
						</HStack>
						<Flex wrap="wrap" gap="2" w="full">
							{yourTrades?.map((trade) => (
								<VStack key={trade.id}>
									<Flex wrap="wrap" gap="4" w="full">
										{trade.levels.map((level) => (
											<CertificateListItem
												key={level.id}
												pro={profile}
												trade={trade}
												tradeLevel={level}
												hasSubmittedBankAccount={hasProvidedBankAccountInfo}
												hasSubmittedGuarantor={hasBackgroundCheckData}
											/>
										))}
									</Flex>
								</VStack>
							))}
						</Flex>
					</VStack>
				)}
				{otherTrades.length && (
					<VStack align="start" w="full">
						{loading && <Skeleton height="20px" width="full" />}

						<VStack align="start" spacing={1}>
							<HStack align="start" w="full">
								<Text
									fontSize="xl"
									fontWeight="semibold"
									className="!m-0"
									color="primary.500"
								>
									Available certificates
								</Text>
							</HStack>
						</VStack>

						{otherTrades?.map((trade) => (
							<VStack key={trade.id}>
								<Flex wrap="wrap" gap="4" w="full">
									{trade.levels.map((level) => (
										<CertificateListItem
											key={level.id}
											pro={profile}
											trade={trade}
											tradeLevel={level}
											hasSubmittedBankAccount={hasProvidedBankAccountInfo}
											hasSubmittedGuarantor={hasBackgroundCheckData}
										/>
									))}
								</Flex>
							</VStack>
						))}
					</VStack>
				)}
			</VStack>
		</>
	);
};

export default CertificatePage;
