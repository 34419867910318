import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Textarea,
} from "@chakra-ui/react";
import { Check, Trash2 } from "lucide-react";
import React, { PropsWithChildren, useEffect } from "react";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { format, isBefore } from "date-fns";
import { PortfolioWorkHistoryItem } from "../../types";
import { FaUpload } from "react-icons/fa";

const EDIT_WORK_HISTORY = gql(`
  mutation EditPortfolioWorkHistoryItem(
    $id: String!
    $name: String
    $description: String
    $startedAt: DateTimeISO
    $images: [JobImage!]
    $videos: [String!]
    $budget: Float
    $endedAt: DateTimeISO
  ) {
    editPortfolioWorkHistoryItem(
      id: $id
      name: $name
      description: $description
      startedAt: $startedAt
      images: $images
      videos: $videos
      budget: $budget
      endedAt: $endedAt
    ) {
      id
      name
      description
      startedAt
      endedAt
      images {
				id
				name
			}
      videos
      budget
      clientReview {
        rating
        feedback
      }
      verified
    }
  }
`);

const REMOVE_WORK_HISTORY = gql(`
  mutation RemovePortfolioWorkHistoryItem($id: String!) {
    removePortfolioWorkHistoryItem(id: $id) {
      id
    }
  }
`);

interface WorkHistoryEditorProps {
	onClose: () => void;
	item: PortfolioWorkHistoryItem & { id: string };
}

const formSchema = z.object({
	taskName: z.string().min(3),
	taskDescription: z.string().min(10),
	budget: z.number().min(1).optional(),
	date: z.string().refine((value) => {
		if (!value) return true;
		return isBefore(new Date(value), new Date());
	}, "Date must be today or in the past"),
	image: z
		.object({
			name: z.string(),
			data: z.string(),
			mimeType: z.string(),
		})
		.optional(),
});

export const WorkHistoryEditor = ({
	onClose,
	item,
}: PropsWithChildren<WorkHistoryEditorProps>) => {
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			taskName: item.name,
			taskDescription: item.description,
			...(item.budget && { budget: item.budget }),
			date: format(new Date(item.endedAt), "yyyy-MM-dd"),
			image: item.images[0],
		},
	});

	const [selectedPortfolioImage, setSelectedPortfolioImage] =
		React.useState("");

	const [isImageUpdated, setIsImageUpdated] = React.useState(false);

	const [editWorkHistory, editWorkHistoryHandle] = useMutation(
		EDIT_WORK_HISTORY,
		{
			onCompleted: () => {
				onClose();
			},
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const [removeWorkHistory, removeWorkHistoryHandle] = useMutation(
		REMOVE_WORK_HISTORY,
		{
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const loading =
		editWorkHistoryHandle.loading || removeWorkHistoryHandle.loading;

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		editWorkHistory({
			variables: {
				id: item.id,
				name: data.taskName,
				description: data.taskDescription,
				budget: data.budget,
				...(data.date && { startedAt: new Date(data.date) }),
				...(isImageUpdated && data.image && { images: [data?.image] }),
				endedAt: null,
			},
		});
	};

	const handleRemove = () => {
		removeWorkHistory({
			variables: {
				id: item.id,
			},
		});
	};

	// Handle file change and preview generation
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				if (reader.result && typeof reader.result === "string") {
					// Set preview for UI
					setSelectedPortfolioImage(reader.result);

					const dataObject = {
						name:
							"job-history-image-" +
							reader.result.split(",")[1].substring(0, 5) +
							`-${Math.random() * 1000}`,
						data: reader.result.split(",")[1],
						mimeType: file.type,
					};

					setValue("image", dataObject);
					setIsImageUpdated(true);
				}
			};

			reader.readAsDataURL(file); // Start reading the file as Base64
		}
	};

	useEffect(() => {
		if (item.images.length > 0) {
			const image = item.images[0];
			setSelectedPortfolioImage(`data:${image.mimeType};base64,${image.data}`);
		}
	}, [item.images]);

	return (
		<div className="flex flex-col py-4 gap-4 w-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-4">
						<FormControl isInvalid={!!errors.taskName} isRequired>
							<FormLabel htmlFor="firstName">Project Name</FormLabel>
							<Input
								id="taskName"
								placeholder="Example: 2HP Split AC Installation"
								{...register("taskName")}
								variant="outline"
							/>
							<FormErrorMessage>{errors.taskName?.message}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.taskDescription} isRequired>
							<FormLabel htmlFor="taskDescription">
								Project Description
							</FormLabel>
							<Textarea
								id="taskDescription"
								placeholder="Example: I installed a 2HP split AC in the second floor living room."
								{...register("taskDescription")}
							/>
							<FormErrorMessage>
								{errors.taskDescription?.message}
							</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.date} isRequired>
							<FormLabel htmlFor="taskDate">Completion Date</FormLabel>
							<Input
								id="taskDate"
								{...register("date")}
								type="date"
								variant="outline"
							/>
							<FormErrorMessage>{errors.date?.message}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.budget}>
							<FormLabel htmlFor="budget">Project Budget</FormLabel>
							<InputGroup>
								<InputLeftAddon>₦</InputLeftAddon>
								<NumberInput
									className="w-full"
									onChange={(_, value) => {
										setValue("budget", value);
									}}
									value={watch("budget")}
									step={1000}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</InputGroup>
							<FormErrorMessage>{errors.budget?.message}</FormErrorMessage>
						</FormControl>
					</div>

					<div className="flex flex-col items-start gap-2">
						<div className="flex flex-col gap-1">
							<Heading as="h4" className="!text-xl !font-medium">
								Media
							</Heading>
							<p className="text-sm text-gray-600">
								Add images or videos to show your prospective clients what you
								can do
							</p>
						</div>

						{selectedPortfolioImage && (
							<div className="flex justify-center items-center w-full">
								<img src={selectedPortfolioImage} alt="Upload Preview" />
							</div>
						)}
						<Box position="relative" display="flex" gap={4}>
							{/* Hidden file input */}
							<Input
								type="file"
								accept="image/*"
								onChange={handleFileChange}
								id="upload-btn"
								hidden
							/>
							<Button
								onClick={() => {
									document.getElementById("upload-btn")?.click();
								}}
								leftIcon={<FaUpload />}
								colorScheme="prussianBlue"
								variant="outline"
							>
								{selectedPortfolioImage ? "Change" : "Choose File"}
							</Button>
						</Box>
					</div>
					<div className="flex justify-between">
						<Button
							colorScheme="red"
							variant="ghost"
							mr={3}
							onClick={handleRemove}
							rightIcon={<Trash2 className="w-5 h-5" />}
							isLoading={loading}
						>
							Remove
						</Button>
						<div>
							<Button
								variant="ghost"
								mr={3}
								onClick={onClose}
								isLoading={loading}
							>
								Close
							</Button>
							<Button
								type="submit"
								colorScheme="green"
								rightIcon={<Check className="w-5 h-5" />}
								isLoading={loading}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
