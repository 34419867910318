import { Icon } from "@chakra-ui/react";
import clsx from "clsx";
import { IconType } from "react-icons";
import { GrLock } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
import { LucideLockKeyhole } from "lucide-react";

export interface SideNavItemProps {
	name: string;
	icon: IconType;
	path: string;
	locked?: boolean;
	active?: boolean;
	onClose?: () => void;
}

export const SideNavItem = ({
	icon,
	name,
	path,
	locked,
	active,
}: SideNavItemProps) => {
	const navigate = useNavigate();

	const handleClick = () => {
		!locked && navigate(path);
	};

	return (
		<div
			className="grid grid-cols-[36px_1fr] w-full items-center pr-8 cursor-pointer"
			onClick={handleClick}
		>
			<div className={clsx("h-full w-1", active && "bg-accent-500")}>
				{/* active bar */}
			</div>
			<div
				className={clsx(
					"grid grid-cols-[36px_1fr_24px] w-full items-center py-3 px-3 rounded-lg",
					active && "bg-accent-100"
				)}
			>
				<div>
					<Icon
						as={icon}
						w={5}
						h={5}
						className={styles.icon}
						color={active ? "brand.500" : "primary.200"}
					/>
				</div>
				<div
					className={clsx(
						"text-sm text-primary-200 font-medium",
						active && "!text-accent-500 !font-semibold"
					)}
				>
					{name}
				</div>
				<div className="justify-self-end">
					{locked && (
						<Icon
							as={GrLock}
							w={4}
							h={4}
							className={styles.icon}
							color={active ? "brand.500" : "primary.200"}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export const SideNavItemV2 = ({
	icon,
	name,
	path,
	locked,
	active,
	onClose,
}: SideNavItemProps) => {
	const navigate = useNavigate();

	const handleClick = () => {
		onClose && onClose();
		!locked && navigate(path);
	};

	return (
		<div
			className="flex w-full justify-between items-center cursor-pointer lg:px-6"
			onClick={handleClick}
		>
			<div
				className={clsx(
					"w-full flex items-center gap-3 lg:gap-4 px-2 lg:px-3 py-2 lg:py-3",
					active && "bg-[#EBEBEB]"
				)}
			>
				<div>
					<Icon as={icon} w={5} h={5} color={active ? "#2F1612" : "#828282"} />
				</div>
				<div
					className={clsx(
						"text-base lg:text-lg text-[#828282] font-medium",
						active && "!text-[#2F1612]"
					)}
				>
					{name}
				</div>
			</div>
			<div className="justify-self-end">
				{locked && (
					<Icon
						as={LucideLockKeyhole}
						w={5}
						h={5}
						className={styles.icon}
						color={active ? "#2F1612" : "#828282"}
					/>
				)}
			</div>
		</div>
	);
};
